import React from "react"

import Layout from "../components/templates/layout"
import SEO from "../components/templates/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Hey - einen Moment wir legen die von dir gesuchte Seite sofort an.</p>
  </Layout>
)

export default NotFoundPage
